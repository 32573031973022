<template>
	<div class="contain" v-if="mockJSon.length!=0">
		<div class="header" flex="corss:center main:justify">
			<div class="subject-type">{{ subTypeText }}</div>
			<div flex="main:center cross:center">
				<div class="num" @click="openSub" flex="main:center cross:center">
						<img class="pro-menu" src="../../assets/problem_menu.png" alt="">	<span flex="cross:center" class="light-color">  {{ oSubIndex+1 }}</span>/<span>{{ mockJSon.length }}</span>
				</div>
			</div>
		</div>
		<div class="card">
			<div class="sub-title"><span v-html="mockJSon[oSubIndex]['question']">{{ mockJSon[oSubIndex]['question'] }}</span></div>
			<div class="sub-answer">
				<!-- 题目类型 -->
				<!-- 多选 -->
				<div v-show="mockJSon[oSubIndex]['style'] == '2' " class="select-multiple" :class="{'select-active': currentSub['answer'].includes(item[0])}" @click="selectMultipe(item[0])" v-for="(item,index) in mockJSon[oSubIndex]['formatOption']" flex="main:left cross:top">
					<div class="left">{{ item[0] }}.</div><div class="right">{{ item[1] }}</div>
				</div>

				<!-- 填空 -->
				<textarea maxlength="30" v-show="mockJSon[oSubIndex]['style'] == '3' " class="sub-type-fill" placeholder="请输入您的答案" v-model="currentSub['answer']" @input="fillSubChange($event)"></textarea>
				<!-- <van-field
				v-model="message"
				rows="2"
				autosize
				type="textarea"
				maxlength="50"
				placeholder="请输入留言"
				show-word-limit
				/> -->
				<div class="fill-tip fill-tip-one" v-show="mockJSon[oSubIndex]['style'] == '3' ">*只能输入中文汉字</div>
				<div class="fill-tip" v-show="mockJSon[oSubIndex]['style'] == '3' ">输入标点符号，英文或表情，算答错，不得分。</div>

				<!-- 单选 -->
				<div v-show="mockJSon[oSubIndex]['style'] == '1' || mockJSon[oSubIndex]['style'] == '5'" class="select-single" :class="{'select-active':item[0] == currentSub['answer']}" @click="selectSingle(item[0])" v-for="(item,index) in mockJSon[oSubIndex]['formatOption']" flex="main:left cross:top">
					<div class="left">{{ item[0] }}.</div><div class="right">{{ item[1] }}</div>
				</div>

				<!-- 判断 -->
				<div v-show="mockJSon[oSubIndex]['style'] == '4' " class="select-judge" :class="{'select-active':item == currentSub['answer']}" @click="selectJudge(item)" v-for="(item,index) in mockJSon[oSubIndex]['formatOption']">
					<div v-if="index == 0" class="judge-icon-box" flex="main:left cross:top">
						<!-- <img src="../../assets/ic_correct-1.png" class="judge-icon" v-if="item == currentSub['answer']"/>
						<img src="../../assets/ic_correct.png" class="judge-icon" v-else/> -->
						<div class="judge-icon">A.</div>
						<div class="judge-word">{{ item }}</div>
					</div>
					<div v-else class="judge-icon-box" flex="main:left cross:top">
						<!-- <img src="../../assets/ic_cansel-1.png" class="judge-icon" v-if="item == currentSub['answer']"/>
						<img src="../../assets/ic_cansel.png" class="judge-icon" v-else/> -->
						<div class="judge-icon">B.</div>
						<div class="judge-word">{{ item }}</div>
					</div>
				</div>
			</div>
		</div>
		<div flex="main:justify cross:center" class="btn-box">
			<van-button type="primary" class="pre" :class="{'btn-top':isPad}" @click='preSub'>上一题</van-button>
			<van-button v-show="oSubIndex < mockJSon.length-1" type="primary" class="next" :class="{'btn-top':isPad}" @click='nextSub'>下一题</van-button>
			<van-button v-show="oSubIndex >= mockJSon.length-1" type="primary" class="submit" :class="{'btn-top':isPad}" @click='handPaperAction'>交卷</van-button>
		</div>
		<!-- 题目快速定位 -->
		<van-popup v-model="show" round position="bottom" @click="show = false">
			<div class="subject-position">
				<div class="title">题目列表</div>
				<!-- 当前对应的实际题目是经过映射后的题目 -->
				<div style="height:auto" flex="dir:lef cross:top" class="subject-position-box">
					<div @click="goSub(index)" class="sub-item" :class="{'subjectStatus': mockJSon[index].answer == ''}" v-for="(item,index) in mockJSon" :key='index'>
					{{ index+1 }}
					<div class="point" v-show="oSubIndex == index"></div>
				</div>
				</div>
			</div>
		</van-popup>
		<!-- 各种情况下的弹框 -->
		<!-- 自然时间到 -->
		<van-overlay :show="timeOver">
			<div class="timeOver">
				<div class="dialog-title">时间到，交卷中....</div>
				<van-loading style="margin-top:0.6rem" color="#27C190"/>
				<!-- <van-button type="primary" class="button">交卷</van-button> -->
			</div>
		</van-overlay>
		<!-- 中途退出再次进入 -->
		<van-overlay :show="enterAgain" @click="enterAgain = false">
			<div class="enter-again">
				<div class="dialog-title">上次中途退出，当前做题</div>
				<div class="dialog-title title">进度 {{ unDown.length }}/{{ mockJSon.length }}</div>
				<div class="time">倒计时：{{ timeRemaning }}</div>
			</div>
		</van-overlay>
		<!-- 主动交卷并做完题了 -->
		<van-overlay :show="commitFinish">
			<div class="commit-finish">
				<div class="dialog-title">确认现在交卷？</div>
				<div class="time">剩余 {{ timeRemaning }}</div>
				<van-button type="primary" class="sure" @click='handPaper(2,1)'>确认交卷</van-button>
				<van-button type="primary" class="continue" @click='commitFinish = false'>继续答题</van-button>
			</div>
		</van-overlay>
		<!-- 主动交卷还有提未做完 -->
		<van-overlay :show="commitUnfinish">
			<div class="commit-unfinish">
				<div class="dialog-title">确认现在交卷？</div>
				<div class="time">剩余 {{ timeRemaning }}</div>
				<div class="unfinish-sub">你有<span class="unfinish-num">{{ unDown.length }}</span>题未作答：<span class="unfinish-sub-no" v-for="(item,index) in unDown" :key='item'> <span class="unfinish-sub-i">第{{ item }}题</span><span class="mark">{{ index!=unDown.length-1?',':'' }}</span></span>
				</div>
				<van-button type="primary" class="sure" @click='handPaper(2,1)'>确认交卷</van-button>
				<van-button type="primary" class="continue" @click='continueAnswer'>继续答题</van-button>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import dataApi from '@/api/apply.js'
	import {
		store,
	} from "@/store/index.js";
	import {
		answer
	} from "@/mixins/answer.js";
	import {
		isPad
	} from "@/util/tools.js";
	export default {
		data() {
			return {
				show: false,
				timeOver: false, //考试时间自然结束
				enterAgain: false, // 中途退出后再次进入
				commitFinish: false, //完成全部答题后,手动提交
				commitUnfinish: false, //未完成全部答题时,手动提交
				oSubIndex: 0, //当前题目的位置
				answerTime: null, // 考试时间 （单位：秒）
				timeRemaning: '', //  考试剩余时间  00:00
				subjectList: [], //   序列化之后题目集合   1单选题 2多选题 3填空题 4判断题
				answerList: {}, //   答案集合
				// currentSub: {}, //当前题目
				unDown: [], //未做的题
				mockJSon: [], //题目的映射数组
				lastTimer: null, //心跳的计时器
				countTimer: null, //倒计时计时器
				STATE:null,
				isPad:false
			}
		},
		mixins: [
			answer
		],
		methods: {
			openSub() { //打开题目定位
				this.show = true
			},
			// 定位到题目具体位置
			goSub(index) {
				// this.answerList[this.currentSub['no']] = this.currentSub
				this.oSubIndex = index
				// this.currentSub = this.mockJSon[index]
			},
			selectSingle(answer) { // 单选题
				this.currentSub['answer'] = answer
			},
			selectJudge(answer) { //判断
				this.currentSub['answer'] = answer
			},
			fillSubChange(e) { //填空题
				this.currentSub['answer'] = this.currentSub['answer'].trim()
			},
			selectMultipe(answer) { // 多选题
				if (this.currentSub['answer'].includes(answer)) {
					let arr = this.currentSub['answer'].split(',')
					arr.splice(arr.findIndex(item => item == answer), 1)
					this.currentSub['answer'] = arr.join(',')
				} else {
					if (this.currentSub['answer']) {
						this.currentSub['answer'] += ',' + answer
					} else {
						this.currentSub['answer'] = answer
					}
				}
				this.currentSub['answer'] = this.currentSub['answer'].split(',').sort().join()
			},
			preSub() { //上一题
				// this.answerList[this.currentSub['no']] = this.currentSub
				if (this.oSubIndex <= 0) {
					return
				}
				this.oSubIndex--
				// this.currentSub = this.mockJSon[this.oSubIndex]
			},
			nextSub() { // 下一题
				// this.answerList[this.currentSub['no']] = this.currentSub
				if (this.oSubIndex >= this.mockJSon.length - 1) {
					return
				}
				this.oSubIndex++
				// this.currentSub = this.mockJSon[this.oSubIndex]
			},
			
			// 交卷时校验当前还有多少题目没做完
			async handPaperAction() { //交卷
				// this.answerList[this.currentSub['no']] = this.currentSub
				const unDown = await this.checkSubStatus()
				this.unDown = unDown
				if (unDown.length == 0) {
					this.commitFinish = true
					return
				} else if(unDown.length == this.mockJSon.length) {
					this.$notify('请作答');
					return
				}else {
					this.unDown = unDown
					this.commitUnfinish = true
				}
			},
			handPaper(state,action) { //交卷  action 1主动交卷  2自动交卷（时间到
				/* type:1:中途退出  2:自动/主动交卷 */
				if (state == 2) {   // 用于主动交卷时，并发太大，延时提交
					this.$toast.loading({
						message: '成绩核算中,请勿关闭页面 !',
						forbidClick: true,
						duration: 0
					});
					let count =  Math.floor(Math.random() * (5 - 1) ) + 1;
					setTimeout(()=>{
						this.$toast.clear()
						this.paperFunction(state,action)
					},count*1000)
				}else{
					this.paperFunction(state)
				}
			},
			async paperFunction(state,action){
				// 格式化answerList
				let answerList = this.mockJSon.map(item => {
					return {
						id: item.id,
						answer: item.answer,
						style: item.style
					}
				})
				/* 更新本地成绩 */
				localStorage.setItem('subjectList', JSON.stringify(this.mockJSon))
				localStorage.setItem('answerList', JSON.stringify(answerList))
				// 试卷提交
				if(state == 1){
					if(this.$route.name === 'AnswerCard' ) {
						localStorage.setItem('oSubIndex', JSON.stringify(this.oSubIndex))	
					}
					dataApi.heart({ data: answerList,no:store.gameno }).then(res=>{
						console.log('息屏回到页面:::'+res.data.lastTime);
						this.answerTime = res.data.lastTime
					}).catch((error)=>{
						if(error.data.code == 104){  //异常操作导致后台已经自动交卷
							clearInterval(this.countTimer)
							clearInterval(this.lastTimer)
							this.$toast(error.data.msg)
							this.$router.push('/')
						}
					})
				}else if(state == 2){
					dataApi.commitPaper({data: answerList,action:action, no:store.gameno}).then(res => {
						localStorage.removeItem('oSubIndex')
						this.$router.replace({
							path: '/completed',
							query: {
								score:res.data.score,
								times:res.data.times,
								extraScore:res.data.extraScore
							}
						})
					}).catch(error=>{
						this.$toast(error.data.msg)
					})
				}
			},
			//未做完题目继续答题
			continueAnswer(){
				this.commitUnfinish = false
				this.oSubIndex = this.unDown[0]-1
			}
		},
		mounted() {
			if(isPad()){
				this.isPad = true
			}else{
				this.isPad = false
			}
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.lastTimer)
			clearInterval(this.countTimer)
			if(to.name != 'completed'){
				this.handPaper(1)
				next()
			}else{
				next()
			}
		},
		computed: {
			// 返回当前真实的题目
			currentSub(){
				return this.mockJSon[this.oSubIndex] 
			},
			subTypeText() {
				let style = this.currentSub.style
				switch (style) {
					case 1:
						return '单选题'
					case 2:
						return '多选题'
					case 3:
						return '填空题'
					case 4:
						return '判断题'
					case 5:
						return '古文题'
					default:
						return '未知题目'
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.contain {
		width: 100%;
		height: 100vh;
		background: url('../../assets/img_bg_dt.png') no-repeat;
		background-size: 100% 100%;
		overflow-y: auto;
	}
	.header{
		height: 0.66rem;
		margin-top: 0.1rem;
		margin-bottom: 0.48rem;
		
	}
	.subject-type {
		color: #FDFDFD;
		font-size: 0.34rem;
		text-indent: 0.25rem;
		float: left;
		height: 0.66rem;
		line-height: 0.66rem;
		background-image: linear-gradient(-89deg, #42B28E 0%, #41816D 100%);
		border-radius: 0 0.33rem 0.33rem 0;
		width: 1.64rem;
	}
	.num {
		font-size: 0.36rem;
		color:rgba(39, 193, 144, 0.4);
		float: right;
		margin: 0 0.32rem 0 0.12rem;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		.pro-menu{
			width: 0.32rem;
			height: 0.32rem;
			margin-right: 0.12rem;
		}
	}
	.light-color{
		color: #28C190;
	}
	.sub-answer{
		width: 100%;
	}
	.card {
		clear: both;
		max-width: 6.9rem;
		height: 8.4rem;
		border-radius: 0.08rem;
		margin: 0 auto;
		overflow-y: auto;
		.sub-type-fill {
			width: 6.26rem;
			height: 1.1rem;
			background-color: #f2f2f2;
			color: #333;
			font-size: 0.36rem;
			text-indent: 0.32rem;
			padding-top: 0.32rem;
			border: none;
			resize: none;
			margin-left: 0.3rem;
			padding: 0;
			padding-top: 0.32rem;
		}
		.fill-tip{
			font-size: 0.24rem;
			color: #CC9877; 
			margin-left: 0.32rem;
			margin-top: 0.16rem;
		}
		.fill-tip-one{
			margin-top: 0.39rem;
		}
		input::-webkit-input-placeholder {
			color: #ccc;
		}

		.select-single,
		.select-multiple,
		.select-judge {
			border-radius: 0.08rem;
			background: #FFFFFF;
			margin: 0 auto;
			margin-bottom: 0.32rem;
			padding: 0.22rem 0;
			font-size: 0.34rem;
			color: #3E4E58;
			.left{
				text-indent: 0.23rem;
				line-height: 0.5rem;
			}
			.right{
				text-align: left;
				line-height: 0.5rem;
			}
		}

		.judge-icon-box {
			width: 100%;
			height: 100%;
			vertical-align: top;
			overflow: hidden;
			.judge-icon {
				text-indent: 0.23rem;
				line-height: 0.5rem;
			}
			.judge-word{
				line-height: 0.5rem;
			}
		}

		.select-active {
			background-color: #F5FFFC;
			color: #2AC190;
			border: 0.02rem solid #27C190;
		}
		.sub-title{
			font-size: 0.34rem;
			color: #333333;
			margin: 0 0 0.64rem 0;
			font-weight: bold;
			line-height: 0.5rem;
		}
	}
	.btn-box{
		padding: 0 0.33rem;
	}
	.pre,
	.next,
	.submit {
		width: 2.8rem;
		height: 0.64rem;
		margin-top: 0.96rem;
		font-size: 0.36rem;
		background: #27C190;
		box-shadow: 0 0.09rem 0.18rem 0 rgba(213,218,223,0.57);
		border-radius: 0.12rem;
		font-size: 0.34rem;
		color: #FFFFFF;
	}
	.pre{
		background: #FFF;
		color: #27C190;
		border: none;
	}
	.next{
		border:	0.01rem solid #37A07F;
	}
	.btn-top{
		margin-top: 0.76rem;
	}
	.buttonStatus {
		opacity: 0.6;
	}

	.subject-position {
		width: 100%;
		min-height: 8rem;
		background-color: #FDFDFD;
		height: 100%;
		float: left;
		padding-bottom: 0.2rem;
		&-box{
			flex-wrap: wrap;
		}
		.sub-item {
			width: 0.56rem;
			height: 0.56rem;
			background: #27C190;
			font-size: 0.32rem;
			color: #FFFFFF;
			border-radius: 50%;
			float: left;
			margin: 0 0 0.4rem 0.56rem;
			text-align: center;
			line-height: 0.56rem;
			position: relative;
			transition: all 0.2s ease-in;
			&:active{
				background: #37A07F;
			}
			.point{
				width: 0.1rem;
				height: 0.1rem;
				background: #2DB2EA;
				position: absolute;
				top: 0.62rem;
				border-radius: 50%;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		.subjectStatus {
			background-color: #FFFFFF;
			color: #27C190;
			border: 0.02rem solid #27C190;
			box-sizing: border-box;
			line-height: 0.52rem;
    		transition: all 0.2s ease-in;
			&:active{
				background: #E5FFF7;
			}
		}
		.title{
			width: 6.38rem;
			height: 0.96rem;
			text-align: center;
			line-height: 0.96rem;
			border-bottom: 1px solid #E5E5E5;
			font-size: 0.36rem;
			color: #333333;
			margin: auto;
			font-weight: bold;
			margin-bottom: 0.24rem;
		}
	}

	/* 各类弹框 */
	.timeOver,
	.enter-again,
	.commit-finish,
	.commit-unfinish {
		width: 5.9rem;
		height: 2.6rem;
		background-color: #fff;
		text-align: center;
		overflow: hidden;
		border-radius: 0.08rem;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		position: absolute;
		padding-bottom: 0.36rem;

		.button {
			background: #27C190;
			border-radius: 0.04rem;
			font-size: 0.36rem;
			color: #fff;
			width: 5.1rem;
			height: 0.72rem;
			margin-top: 0.56rem;
			margin-bottom: 0.48rem;
			border-radius: 0.04rem;
			border: none;
		}
	}

	.timeOver {
		height: 2.6rem;

		.button {
			margin-bottom: 0.45rem !important;
		}
	}

	.enter-again {
		height: 2.76rem;

		.title {
			margin-top: 0.1rem;
		}
	}

	.commit-finish,
	.commit-unfinish {
		height: 3.2rem;

		.sure,
		.continue {
			height: 0.72rem;
			width: 2.34rem;
			background-color: #FFF;
			border: none;
			border-radius: 0.04rem;
			font-size: 0.32rem;
			float: left;
			margin-top: 0.56rem;
			border: 1px solid #27C190;
			color: #27C190;;
		}

		.sure {
			margin-right: 0.24rem;
			color: #27C190!important;
			margin-left: 0.42rem;
		}
		.continue {
			background: #27C190;
			color: #fff;
		}
	}

	.commit-finish {
		height: 3.2rem;
		.sure,
		.continue {
			margin-bottom: 0.48rem !important;
		}
	}

	.commit-unfinish {
		height: 4.2rem;

		.unfinish-num {
			color: #3F1500;
		}

		.unfinish-sub-i {
			color: #27C190;
		}

		.mark {
			color: #27C190;;
		}
	}

	.unfinish-sub {
		width: 4rem;
		font-size: 0.3rem;
		margin: 0 auto;
		margin-top: 0.4rem;
		line-height: 0.4rem;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.dialog-title {
		color: #333;
		font-size: 0.4rem;
		margin-top: 0.48rem;
		font-weight: bold;
	}

	.time {
		color: #666;
		font-size: 0.28rem;
		margin-top: 0.32rem;
	}
</style>
