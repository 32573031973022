<template>
	<div class="contain">
		<div class="user-info">
			<div class="top" flex="main:center dir:top">
				<div class="name-box">亲爱的 <span class="name">{{ name }}</span></div>
				<div class="activity-name">欢迎参加2022浙江少年诗词大会{{ store.gameType == 1 ? '初选' : '复选' }}</div>
			</div>
			<div class="sub-type sub-type-top"><span>测评题型：</span>单选题、多选题、判断题、古文题</div>
			<div class="sub-type"><span>测评时间：</span>{{ store.gameType == 1 ? '20' : '25' }}分钟，50题</div>
			<div class="sub-type"><span>晋级标准：</span>本轮晋级前100位</div>
			<div class="sub-type"><span>计分规则：</span>错1题扣2分，多选题少选漏选扣1分;</div>
			<div class="sub-type additional">10分钟内作答完，额外加5分。</div>
			<!-- <div class="sub-type additional">10分钟内作答完，额外加10分。</div>
			<div class="sub-type additional">10-15分钟内作答完，额外加3分。</div> -->
		</div>
		<div class="rule-content">
			<div class="text" flex="main:center cross:center">活动说明</div>
			<div class="rules">
				<div>在活动报名之前，请务必仔细阅读本条款并同意本声明，点击“同意”，即表示接受并同意所有条款。如在活动过程中有任何违反本声明的行为，
					2022浙江少年诗词大会(以下简称：大会）组委会有权取消参与资格及所有相关权益。</div>
				<div>一、仔细阅读《活动手册》，理解并同意其全部内容，符合以下条件：</div>
				<div>（一）活动对象</div>
				<div>对经典古诗文和优秀传统文化有兴趣的浙江中小学生均可报名参加。</div>
				<div>（二）活动时间</div>
				<div>初选：2022年11月每周六的06:00 - 22:00 ( 11月5日、12日、19日、26日 )</div>
				<div>复选：2022年12月3日，线上进行，19：00准时开始。</div>
				<div>决选：具体时间届时另行通知</div>
				<div>（三）活动形式 </div>
				<div>初选：线上答题，时间为20分钟，50道题。题型为判断题、单项选择题、不定项选择题。</div>
				<div>复选：线上答题，时间为25分钟，50道题。题型为判断题、单项选择题、不定项选择题。</div>
				<div>决选：根据复选结果和疫情防控对场地等要求，另行通知。</div>
				<div>二、参会者必须以善意且谨慎的态度行事，不得填写和传播违反中国法律、国际公约以及社会公德的信息。</div>
				<div>三、个人资料的保护及其限制</div>
				<div>1、 对于报名时填写的资料，除下列情形外，大会组委会同意在未得到用户同意前，不公开对外披露：</div>
				<div>①基于法律规定；</div>
				<div>②基于司法机关或其它有权机关基于法定程序的要求；</div>
				<div>③为保障大会的财产及权益；</div>
				<div>④在紧急情况下为保护其它用户或第三人的人身安全的情形下；</div>
				<div>⑤大会及其合作对象，可以在合理范围内搜集、处理、保存、传递及使用该资料，以提供参会者其它信息及服务或做大数据统计，或进行网络行为的调查或研究，或其它任何合法使用。</div>
				<div>2、参会者应妥善保管自己的账号和密码。因他人盗用登录密钥造成后果的，或者因不可抗力造成个人信息泄漏，大会组委会不承担任何责任。</div>
				<div>3、本声明条款的解释，效力及纠纷的解决，适用于中华人民共和国法律。</div>
				<div>4、大会组委会对本声明拥有最终解释权。</div>
			</div>
		</div>
		<van-button v-show="time" type="primary" class="btn">我已知晓并同意，开始答题({{ time }})</van-button>
		<van-button v-show="!time" type="primary" class="btn-ready" @click='goAnswerCard'>我已知晓并同意，开始答题</van-button>
	</div>
</template>

<script>
	import dataApi from '@/api/apply.js'
	import { store } from "@/store/index.js";
	export default {
		data() {
			return {
				time: 5,
				timer: null,
				name: '',
				store: store
			}
		},
		methods: {
			timeCount() {
				this.timer = setInterval(() => {
					if (this.time == 0) {
						clearInterval(this.timer)
						this.timer = null
						return
					}
					this.time--
				}, 1000)
			},
			goAnswerCard() {
				this.$router.replace('/AnswerCard')
			},
		},
		mounted() {
			dataApi.getUserInfo().then(res => {
				const {
					name
				} = res.data
				this.name = name
			})
			this.timeCount()
			localStorage.removeItem('oSubIndex')
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}
	}
</script>

<style scoped="scoped" lang="less">
	.contain {
		width: 100%;
		background-color: #f2f2f2;
		float: left;
		position: relative;
	}

	.user-info {
		width: 6.9rem;
		background-color: #FDFDFD;
		border-radius: 0.16rem;
		overflow: hidden;
		margin: auto;
		margin-top: 0.32rem;
		padding-bottom: 0.2rem;
		.top{
			height: 1.34rem;
			width: 100%;
			background-image: linear-gradient(-89deg, #42B28E 0%, #41816D 100%);
			overflow: hidden;
			margin-bottom: 0.08rem;
		}
		.name-box {
			color: #333;
			font-size: 0.26rem;
			text-align: center;
			color: #F7F7F7;
		}

		.name {
			color: #f7f7f7;
			font-size: 0.36rem;
			font-weight: bold;
		}

		.activity-name {
			margin-top: 0.04rem;
			font-size: 0.28rem;
			color: #f7f7f7;
			text-align: center;
			height: 0.42rem;
			line-height: 0.42rem;
		}

		.sub-type-top {
			margin-top: 0.24rem;
		}

		.sub-type {
			font-size: 0.26rem;
			color: #151515;
			position: relative;
			margin-left: 0.55rem;
			margin-top: 0.24rem;
			height: 0.4rem;
			line-height: 0.4rem;
			span{
				color: #999;
			}
		}
		.additional {
			margin-top: 0;
			margin-left: 1.85rem;
		}
	}

	.text {
		font-size: 0.28rem;
		color: #fff;
		width: 100%;
		height: 0.8rem;
		background-image: linear-gradient(-89deg, #42B28E 0%, #41816D 100%);	
	}
	.rule-content {
		width: 6.9rem;
		height: auto;
		margin: auto;
		overflow: hidden;
		border-radius: 0.16rem;
		background-color: #fff;
		margin-top: 0.32rem;
	}
	.rules {
		height: auto;
		margin-top: 0.16rem;
		font-size: 0.26rem;
		line-height: 0.52rem;
		margin-bottom: 1.2rem;
		color: #666;
		margin-left: 0.27rem;
	}

	.btn,
	.btn-ready {
		width: 100%;
		height: 0.89rem;
		border: none;
		font-size: 0.32rem;
		position: fixed;
		bottom: 0;
		left: 0;
		background: #abe5d1;
	}

	.btn-ready {
		color: #fff;
		background-image: linear-gradient(-89deg, #42B28E 0%, #41816D 100%);
	}
</style>
